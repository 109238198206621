// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-employee-js": () => import("./../../../src/pages/employee.js" /* webpackChunkName: "component---src-pages-employee-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-apply-now-js": () => import("./../../../src/pages/jobs/apply-now.js" /* webpackChunkName: "component---src-pages-jobs-apply-now-js" */),
  "component---src-pages-jobs-index-js": () => import("./../../../src/pages/jobs/index.js" /* webpackChunkName: "component---src-pages-jobs-index-js" */),
  "component---src-templates-apply-now-template-js": () => import("./../../../src/templates/ApplyNowTemplate.js" /* webpackChunkName: "component---src-templates-apply-now-template-js" */),
  "component---src-templates-news-index-template-js": () => import("./../../../src/templates/NewsIndexTemplate.js" /* webpackChunkName: "component---src-templates-news-index-template-js" */),
  "component---src-templates-project-category-template-js": () => import("./../../../src/templates/ProjectCategoryTemplate.js" /* webpackChunkName: "component---src-templates-project-category-template-js" */),
  "component---src-templates-project-template-js": () => import("./../../../src/templates/ProjectTemplate.js" /* webpackChunkName: "component---src-templates-project-template-js" */),
  "component---src-templates-projects-index-template-js": () => import("./../../../src/templates/ProjectsIndexTemplate.js" /* webpackChunkName: "component---src-templates-projects-index-template-js" */),
  "component---src-templates-service-template-js": () => import("./../../../src/templates/ServiceTemplate.js" /* webpackChunkName: "component---src-templates-service-template-js" */)
}

